define([
    'santa-components',
    'prop-types',
    'lodash',
    'coreUtils',
    'image-client-api',
    'componentsCore'
], function (
    santaComponents,
    PropTypes,
    _,
    coreUtils,
    imageClientLib,
    componentsCore
) {
    'use strict';

    /**
     *
     * @param props
     * @returns {{}}
     */
    function getOverlayBgImage(props) {
        const {imageOverlay, getMediaFullStaticUrl, currentUrl} = props;
        /*
         * @param {object} imageInfo.imageData
         * @param {number} imageInfo.containerWidth
         * @param {number} imageInfo.containerHeight
         * @param {string} [imageInfo.fittingType]
         */

        if (imageOverlay) {
            const imageInfo = {
                imageData: props.imageOverlay,
                containerWidth: props.imageOverlay.width,
                containerHeight: props.imageOverlay.height,
                displayMode: imageClientLib.fittingTypes.TILE
            };
            const imageTransformObject = coreUtils.imageUtils.getImageComputedProperties(imageClientLib, imageInfo, getMediaFullStaticUrl, currentUrl, 1, imageClientLib.htmlTag.BG);
            return `url(${imageTransformObject.uri})`;
        }
        return '';
    }

    /**
     *
     * @param props
     * @returns {number}
     */
    function getOverlayOpacity(props) {
        const {blendMode, mixBlendModeSupported, blendModeOpacity} = props;
        if (blendMode) {
            const isMixBlendModePolyfill = !mixBlendModeSupported() && blendMode === 'overlay';
            return isMixBlendModePolyfill ? blendModeOpacity / 2 : blendModeOpacity;
        }
        return 1;
    }

    function getOverlayStyle(props) {
        return {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            backgroundImage: getOverlayBgImage(props),
            opacity: getOverlayOpacity(props)
        };
    }

    function getOuterStyle(props) {
        const backgroundColor = props.colorOverlay ? coreUtils.colorParser.getColor(props.colorsMap, props.colorOverlay, props.colorOverlayOpacity) : '';
        const mixBlendMode = props.blendMode || '';
        return _.assign({
            position: props.isExperimentOpen('bv_wixImagePhaseTwo') && shouldRenderFullScreen(props) ? 'fixed' : 'absolute',
            width: '100%',
            height: '100%',
            top: 0
        },
        backgroundColor && {backgroundColor},
        mixBlendMode && {mixBlendMode},
        props.style);
    }

    function shouldRenderFullScreen({isFullScreenHeight, fixedBackgroundColorBalata, renderFixedPositionBackgrounds}) {
        return fixedBackgroundColorBalata && renderFixedPositionBackgrounds && isFullScreenHeight;
    }

    /**
     * @class components.bgImage
     * @extends {core.skinBasedComp}
     */

    return {
        displayName: 'bgOverlay',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            style: PropTypes.object,
            colorOverlay: PropTypes.string,
            colorOverlayOpacity: PropTypes.number,
            imageOverlay: PropTypes.object,
            isFullScreenHeight: PropTypes.bool,
            blendMode: PropTypes.string,
            blendModeOpacity: PropTypes.number,
            mixBlendModeSupported: santaComponents.santaTypesDefinitions.BrowserFlags.mixBlendModeSupported.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            colorsMap: santaComponents.santaTypesDefinitions.Theme.colorsMap.isRequired,
            fixedBackgroundColorBalata: santaComponents.santaTypesDefinitions.BrowserFlags.fixedBackgroundColorBalata.isRequired,
            renderFixedPositionBackgrounds: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionBackgrounds,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl,
            getMediaFullStaticUrl: santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl
        },
        componentDidLayout() {
            // const overlayNode = this.refs[utils.balataConsts.OVERLAY];
            // overlayNode.style.backgroundImage = overlayNode.dataset.backgroundImage;
            // //chrome specific issue #CLNT-6532 , chrome smooth scrolling isnt synced with animation frame.
            // //workaround: placing the background color as fixed layer
            // overlayNode.parentElement.style.position = overlayNode.dataset.position;
        },
        getSkinProperties() {
            const rootAttributes = {
                style: getOuterStyle(this.props)
            };

            if (!this.props.isExperimentOpen('bv_wixImagePhaseTwo')) {
                rootAttributes['data-background-image'] = getOverlayBgImage(this.props);
                rootAttributes['data-position'] = shouldRenderFullScreen(this.props) ? 'fixed' : 'absolute';
            }

            return {
                '': rootAttributes,
                [coreUtils.mediaConsts.balataConsts.OVERLAY]: {
                    style: getOverlayStyle(this.props)
                }
            };
        }
    };
});
